import React from 'react';
import Segment from '@components/segment';
import Layout from '@components/layout';
import Seo from '@components/seo';
import HeroCTA from '@components/sections/heroCTA/heroCTA';
import SocialProofQuoteCardST from '@components/staticSections/SocialProofQuoteCardST/SocialProofQuoteCardST';
import GetStartedST from '@components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '@components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import OutcomeST from '@components/staticSections/OutcomeST/OutcomeST';
import Treatments from '@components/staticSections/Treatments/Treatments';
import HeaderImage from '@/src/images/Logo-Vivolcab-bgwhite.png';

const IndexPage = () => {
  const lang = 'fr_fr';
  return (
    <Layout
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      headerWitdh="header"
      headerStyle="flex--space-between"
      showFooter
      lang="fr"
      headerImage={
        <img className="logo--sm margin-left--xs flex" alt="Vivolcab" src={HeaderImage} />
      }
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_FR} />
      <Seo title="Vivolcab" language="fr" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedataNoCerts
        dataSection="hero_CTA"
        alt=""
        title="Une appli pour soulager l’arthrose et les douleurs articulaires"
        titleClassName="bold"
        text="Un suivi par un kinésithérapeute agréé et des exercices adaptés pour soulager vos douleurs au quotidien."
        tagHeadline="Aucun frais – couvert par l’assurance maladie et votre mutuelle."
        smsTag
        dataToken="eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NjU0MTA2OTQsImp0aSI6IjFmZDk2YWM4LWMzMWUtNGM1Ni1iMWY1LTRjNzE1YWY1NGE0ZCIsImFmZmlsaWF0ZV9uYW1lIjoiTWF4aW1lIENIRVZBTExJRVIifQ.hgKWfwjbFtec9NqDgh18BZSkszmUmrYwv6uPBSwjlw0"
      />
      <SocialProofQuoteCardST
        lang={lang}
        dataTheme="white"
        extraMargin="margin-top--lg"
      />
      <GetStartedST
        lang={lang}
        renderDownloadButton
        ctaButtonText="C’EST PARTI !"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="#herotext"
      />
      <BgCardsTextST dataTheme="light" lang={lang} />
      <Treatments lang={lang} downloadhref="#herotext" />
      <SocialProofFeedST lang={lang} sectionClassName="margin-bottom--lg" />
      <OutcomeST lang={lang} />
    </Layout>
  );
};

export default IndexPage;
