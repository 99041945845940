import React from "react"
import contentData from "../../../components/staticSections/OutcomeST/data.json"

const OutcomeST = ({ lang }) => {
  const language = typeof lang === "undefined" ? "se_sv" : lang
  const content = contentData[language]

  return (
    <section data-theme="light" className="OutcomeST">
      {content &&
        content.map(main => (
          <div
            key={main}
            className="container container--md margin-top--lg margin-bottom--md"
          >
            <div className="text--component margin-bottom--md text--center">
              <h2>{main.title}</h2>
              <p>{main.text}</p>
            </div>
            <div className="swiper-container swiper--cards cards--float">
              <div className="swiper-wrapper">
                {main.cards &&
                  main.cards.map(card => (
                    <div key={card.title} className="swiper-slide">
                      <div className="flex flex--column flex--center extraPad">
                        <p className="text--title text--bold color--electric-blue text--xxxl">
                          {card.data}
                        </p>
                        <p className="margin-bottom--xs text--title text--md">
                          {card.title}{" "}
                          <sup className="text--xs">{card.journalTarget}</sup>
                        </p>
                      </div>
                    </div>
                  ))}
                <div className="swiper-pagination  margin-top--md"></div>
              </div>
            </div>
            <div className="col col--12 col--lg-12">
              <div className="flex flex--column margin-top--lg">
                {main.journals &&
                  main.journals.map(journal => (
                    <div key={journal.text}>
                      <p className="text--xxs opacity--6 text--center margin-bottom--xxxs">
                        <a href={journal.url} className="color--dark-grey-blue">
                          {journal.text}
                        </a>
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
    </section>
  )
}

export default OutcomeST
